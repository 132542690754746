<template>
  <div>
    <div>
      <KTPortlet title="View assigned templates">
        <template v-slot:toolbar>
          <div class="dropdown dropdown-inline">
            <button
              type="button"
              class="btn btn-clean btn-sm btn-icon btn-icon-md"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="flaticon2-settings"></i>
            </button>
            <div
              class="
                dropdown-menu
                dropdown-menu-right
                dropdown-menu-fit
                dropdown-menu-md
              "
            >
              <div style="padding: 20px">
                <b-form-group label="View">
                  <b-form-radio v-model="showTreeView" size="lg" :value="false"
                    >Table view</b-form-radio
                  >
                  <b-form-radio v-model="showTreeView" size="lg" :value="true"
                    >Hierarchical view</b-form-radio
                  >
                </b-form-group>
              </div>
            </div>
          </div>
        </template>
        <template slot="body">
          <Loader v-if="templates === null" :inContent="true"
            >Loading...</Loader
          >
          <v-treeview
            v-else-if="showTreeView"
            :items="mutatedTreeViewOfTemplates"
          >
            <template v-slot:prepend="{ item }">
              <v-icon
                v-if="item.children"
                v-text="`mdi-folder-network`"
              ></v-icon>
              <v-icon v-else v-text="`mdi-home-variant`"></v-icon>
            </template>
          </v-treeview>
          <b-table-simple v-else responsive>
            <b-thead>
              <b-tr>
                <b-th sticky-column>Name</b-th>
                <b-th>Device Type</b-th>
                <b-th>Parent Template</b-th>
                <b-th></b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="template in templates" v-bind:key="template.id">
                <b-th sticky-column>{{ template.name }}</b-th>
                <b-td>
                  {{ template.type.brand.toUpperCase() }}
                  <span v-if="template.type.make !== null">
                    / {{ template.type.make.toUpperCase() }}</span
                  >
                  <span v-if="template.type.model !== null">
                    / {{ template.type.model.toUpperCase() }}</span
                  >
                </b-td>
                <!-- <b-td>[{{GetRouterType(template.routerTypeId).name}}] {{GetRouterType(template.routerTypeId).make}} {{GetRouterType(template.routerTypeId).model}}</b-td> -->
                <b-td
                  v-if="!$tools.isNullOrUndefined(template.createdForDeviceId)"
                >
                  device template (no parent)
                </b-td>
                <b-td
                  v-else-if="
                    $tools.isNullOrUndefined(template.parentTemplateId)
                  "
                ></b-td>
                <b-td v-else>{{
                  GetTemplate(template.parentTemplateId).name
                }}</b-td>
                <b-td>
                  <b-button
                    variant="link"
                    size="sm"
                    @click="
                      $router.push(
                        `/Networks/${$route.params.serviceId}/Templates/${template.id}`
                      )
                    "
                  >
                    <!-- View{{ template.isBase !== true ? "/Edit" : "" }} -->
                    <i class="flaticon2-settings"></i>
                  </b-button>
                  <!-- <router-link
                    :to="`/Networks/${$route.params.serviceId}/Templates/${template.id}`"
                  >
                    <b-button variant="info"
                      >View{{
                        template.isBase !== true ? "/Edit" : ""
                      }}</b-button
                    > 
                  </router-link> -->
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </template>
      </KTPortlet>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
//import Swal from "sweetalert2";
import TLib from "./lib";

export default {
  name: "Templates",
  components: {
    KTPortlet,
    Loader,
  },
  watch: {},
  data() {
    return {
      showTreeView: false,
      currentPage: 1,
      perPage: 50,
      templates: null,
      types: null,
    };
  },
  methods: {
    GetTemplate(templateId) {
      if (this.$tools.isNullOrUndefined(templateId)) return { name: "" };
      for (let tTemplate of this.templates)
        if (tTemplate.id === templateId) return tTemplate;

      return null;
    },
    GetRouterType(typeId) {
      for (let tType of this.types) if (tType.id === typeId) return tType;

      return null;
    },
    GetMutatedChildren(thisId) {
      let list = [];
      for (let tTemplate of this.templates)
        if (tTemplate.parentTemplateId === thisId) list.push(tTemplate);
      return list;
    },
    GetMutatedObject(thisId) {
      let children = this.GetMutatedChildren(thisId);
      for (let i = 0; i < children.length; i++) {
        let iChildren = this.GetMutatedObject(children[i].id);
        if (iChildren.length > 0) children[i].children = iChildren;
      }
      return children;
    },
    updateData() {
      let self = this;
      TLib.getTypes(self, self.$route.params.serviceId)
        .then((typex) => {
          self.$data.types = typex;
          TLib.getTemplates(self, self.$route.params.serviceId)
            .then((data) => {
              let outData = [];
              for (let templ of data) {
                if (templ.deleted === true) continue;
                outData.push(templ);
              }
              self.$data.templates = outData;
            })
            .catch((x) => {
              self.$log.error(x);
            });
        })
        .catch((x) => {
          self.$log.error(x);
        });
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },

    mutatedTreeViewOfTemplates() {
      return this.GetMutatedObject(null);
    },
  },
  beforeDestroy() {
    this.$eventBus.off(`switch-service-selected`);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Networks" },
      { title: "Templates" },
    ]);
    let self = this;
    this.$eventBus.on(`switch-service-selected`, () => self.updateData());
    self.updateData();
  },
};
</script>
